import Sniffer from 'snifferjs';

// store
import { app } from '../utils/store';

// import Scroll from '../modules/scroll/scroll';
import Header from '../modules/header/header';
import Carousel from '../modules/carousel/carousel';
import Counters from '../modules/counters/counters';
// import Video from '../modules/video/video';
import Gallery from '../modules/gallery/gallery';
import Filters from '../modules/filters/filters';
import Footer from '../modules/footer/footer';
import Search from '../modules/search/search';
// import Mosaic from '../modules/mosaic/mosaic';
import Modal from '../modules/modal/modal';
// import Animations from '../modules/animations/animations';

export default class Components {
    constructor() {
        this.header = document.querySelector('header');
        this.menu = document.getElementById('main-menu');
        this.footer = document.querySelector('footer');
        this.video = document.querySelector('video');
        this.counters = document.querySelector('.counters .number');
        this.search = document.getElementById('search-toggler');
        this.gallery = document.getElementById('gallery');
        this.filters = document.getElementById('filters');
        this.carousel = document.querySelector('.carousel');
        // this.mosaic = document.querySelector('.mosaic');
        this.modal = document.querySelector('.btn-modal');
        this.animations = document.querySelector('body');

        this.page = {
            test: document.querySelector('.page-test'),
        }

        this.post = {
            test: document.querySelector('.page-test-article'),
        }
    }

    init(){
        // app.scroll = new Scroll();
        // app.scroll.init();

        // Progressive Loading Image (Need 'data-src' or 'data-background' attribute in your HTML)
        // app.scroll.progressiveimgs();

        if(this.header){
            this.header = new Header();
            this.header.init();
        }

        if(this.footer){
            this.footer = new Footer();
            this.footer.init();
        }
        if(this.search){
            this.search = new Search();
            this.search.init();
        }

        if(this.carousel){
            this.carousel = new Carousel();
            this.carousel.init();
        }
        if(this.filters){
            this.filters = new Filters();
            this.filters.init();
        }
        // if(this.mosaic){
        //     this.mosaic = new Mosaic();
        //     this.mosaic.init();
        // }
        // if(this.video){
        //     this.video = new Video();
        //     this.video.init();
        // }

        if(this.counters){
            this.counters = new Counters();
            this.counters.init();
        }

        if(this.gallery){
            this.gallery = new Gallery();
            this.gallery.init();
        }

        if(this.modal){
            this.modal = new Modal();
            this.modal.init();
        }
        // if(this.animations){
        //     this.animations = new Animations();
        //     this.animations.init();
        // }
    }

    destroy = () => {
        console.log('Destroy Components')
        // Destroy Components using JS
    }
}
