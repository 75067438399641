import { app } from '../../utils/store';

export default class Search {
    constructor() {
        this.menu = {
            toggler: document.getElementById('menu-toggler'),
            main: document.getElementById('main-menu'),
            quick: document.querySelector('header .quick-menu'),
            dropdown: document.querySelectorAll('#main-menu .dropdown > .dropdown-link'),
            megaMenu: document.getElementById('mega-menu-kinds-nav'),
            subMegaMenu: document.getElementById('item-pr'),
        }

        this.search = {
            toggler: document.getElementById('search-toggler'),
            main: document.getElementById('search'),
        }

        this.header = document.querySelector('header');
        this.body = document.querySelector('body');
    }

    init() {
        this.toggle();
        // if(!Sniff.features.mobile){ this.language(); }
    }

    toggle = () => {
        if (this.search.toggler) {
            this.search.toggler.addEventListener('click', () => {
                this.header.classList.add('search-active');
                this.body.classList.toggle('overflow-hidden');
                this.search.main.classList.toggle('hidden-search');
                this.menu.megaMenu.classList.add('hidden-submenu');
                this.menu.subMegaMenu.classList.toggle('no-events');
                this.menu.toggler.classList.toggle('no-events');
                app.overlay.classList.toggle('active');
                
                this.menu.toggler.classList.toggle('disabled');
                this.menu.quick.classList.toggle('disabled');
            });
        }

        // if(this.search.main.classList.contains('active')){
        //     this.search.main.classList.remove('active');
        //     app.overlay.classList.remove('active');
        // }
    }
}
