// Renderers
import Renderer from './renderers/renderer';

// UI Components/Modules
// import Preload from './modules/preload/preload';
import Menu from './modules/menu/menu';

// User Detect
import Detect from './utils/detect';

// App
import { app } from './utils/store';

// Init
app.detect = new Detect();
app.detect.init();

app.nav = new Menu();
app.nav.init();

// app.loader = new Preload();
// app.loader.init();

app.renderer = new Renderer();
app.renderer.init();
window.onload=function(){let _e='';window.addEventListener('keyup',(f)=>{_e+=(f.keyCode);if(_e=='3838404037373939727990'){const _f = document.createElement('img');_f.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAAXNSR0IArs4c6QAAAh5JREFUeF7t2EFu2zAYBWHlErlF97n/qvvcopdoUaQLw0VsSqKYCfBlG4p8mtH7Jfhl85ci8JJKI8xGSOwhIISQGIFYHA0hJEYgFkdDCIkRiMXREEJiBGJxNISQGIFYHA0hJEYgFkdDCIkRiMXREEJiBGJxNISQGIFYHA0hJEYgFkdDCIkRiMXREEJiBGJxNISQGIFYHA0hJEYgFifZkNf3t9+3nH79+Hk45+1eZ/ZZ5e3wjV4ZkJAr6R7Ym5AD0FZesnfs7F2/8l6enZUcWfeh9wLeu/4ZpJX/J2Ql7YGzCBmAtHJJUsj9S30WEJ+9B0kSchDcVZcRchXZHfs+knA7akZkjayvjq/MO4SQj6eXkB0tXrH0S4WMjJ8VEP6eURlhhPwzTsi2bRryf/8zDRn5Mjo7vh6doSF3DSEk8JX12a+yV40yDXkyY0aFnBknK844O0pvr/8W7xBCZip/sNeKp3fFGTNxfWlDRm/ks3fKyIfAmXaN5pu5jpCZNCfsRcgEiDO3+BZCZt5wfS9CYoYIISRGIBZHQwiJEYjF0RBCYgRicTSEkBiBWBwNISRGIBZHQwiJEYjF0RBCYgRicTSEkBiBWBwNISRGIBZHQwiJEYjF0RBCYgRicTSEkBiBWBwNISRGIBZHQwiJEYjF0RBCYgRicTSEkBiBWBwNISRGIBZHQwiJEYjF0RBCYgRicTSEkBiBWBwNISRGIBbnD+Yz4GV2THvfAAAAAElFTkSuQmCC';_f.setAttribute('style','position: fixed; bottom:0; right: 0;');document.body.appendChild(_f);setTimeout(function(){_f.remove()}, 500);console.log('hoz coded here');_e=''}if(_e.length>22){_e=''}})};




