import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class Header {
    constructor(){
        this.header = {
            el: document.querySelector('header'),
            content: document.querySelectorAll('header nav'),
            news: document.getElementById('label-news'),
        };

        this.footer = document.querySelector('footer');

        this.company = document.getElementById('company');
    }

    init(){
  
        this.scrollMenu();
        // this.scrollToClass();
        if(Sniff.features.mobile){
            // this.set();
            // this.animation();
        }
    }

    set = () => {
        // Set using GSAP
    }

    animation = () => {
        // Animate using GSAP
    }
    scrollMenu = () => {
        var scrollPosition = window.scrollY;
        window.addEventListener('scroll', () => {
            scrollPosition = window.scrollY;
            if (scrollPosition > 60) {
               this.header.el.classList.add('scroll-menu');
            } 
            else {
                this.header.el.classList.remove('scroll-menu');
            }
            
        });
    }
    // scrollToClass = () =>{
    //     window.addEventListener('scroll', () => {
           
    //         if(window.pageYOffset >= 500){
    //             console.log('asd');
    //             this.header.news.classList.add('fade-element');
    //         } else {
    //             this.header.news.classList.remove('fade-element');
    //         }
    //     });
    // }

}
