import $ from "jquery";
import "slick-carousel";

export default class Carousels {
	constructor(){
		this.carousel = {
			single_item: document.querySelector('.carousel[data-carousel="single-item"]'),
			three_items: document.querySelector('.carousel[data-carousel="three-items"]'),
            three_items_v2: document.querySelector('.carousel[data-carousel="three-items-v2"]'),
            gallery_center: document.querySelector('.carousel[data-carousel="gallery-center"]'),
            product_list: document.querySelector('.carousel[data-carousel="product-list"]'),
            mega_menu_kinds: document.querySelector('.carousel[data-carousel="mega-menu-kinds"]'),
            mega_menu_kinds_alternative: document.querySelector('.carousel[data-carousel="mega-menu-kinds-alternative"]'),
            mega_menu_kinds_nav: document.querySelector('.carousel[data-carousel="mega-menu-kinds-nav"]'),
            reference: document.querySelector('.carousel[data-carousel="reference"]'),
		};
	}

	init() {
		if(this.carousel.single_item) { this.singleItem(); }
		if(this.carousel.three_items) { this.threeItems(); }
        if(this.carousel.three_items_v2) { this.threeItemsV2(); }
        if(this.carousel.gallery_center) { this.galleryCenter(); }
        if(this.carousel.product_list) { this.productList(); }
        if(this.carousel.mega_menu_kinds) { this.megaMenuKinds(); }
        if(this.carousel.mega_menu_kinds_alternative) { this.megaMenuKindsAlternative(); }
        if(this.carousel.mega_menu_kinds_nav) { this.megaMenuKindsNav(); }
        if(this.carousel.reference) { this.reference(); }
	}

	singleItem = () => {
		var carousel = $('.carousel[data-carousel="single-item"]');
        
        carousel.each(function(){
            $(this).not('.slick-initialized').slick({
                mobileFirst: true,
                arrows: false,
                dots:true,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 4000,
                speed: 1500,
            });
        })
	}
    threeItems = () => {
		var carousel = $('.carousel[data-carousel="three-items"]');
        
        carousel.each(function(){
            var arrowsContainer = '<div class="arrows-only"></div>';
            $(this).parent().append(arrowsContainer);
            
            $(this).not('.slick-initialized').slick({
                mobileFirst: true,
                arrows: true,
                prevArrow: '<button type="button" class="btn-prev"></button>',
                nextArrow: '<button type="button" class="btn-next"></button>',
                dots:false,
                appendArrows: $(this).parent().find('.arrows-only'),
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                autoplay: false,
                adaptiveHeight: true,
                responsive: [
                    {
                    breakpoint:767,
                    settings: {
                        slidesToShow: 2,
                        }
                    },
                    {
                    breakpoint:800,
                    settings: {
                        slidesToShow: 3,
                    },
                }
            ],
            });
        })
	}
	threeItemsV2 = () => {
		var carousel = $('.carousel[data-carousel="three-items-v2"]');
        
        carousel.each(function(){
            var arrowsContainer = '<div class="arrows-only"></div>';
            $(this).parent().append(arrowsContainer);
            
            $(this).not('.slick-initialized').slick({
                mobileFirst: true,
                arrows: true,
                prevArrow: '<button type="button" class="btn-prev"><i class="bi bi-arrow-left"></i></button>',
                nextArrow: '<button type="button" class="btn-next"><i class="bi bi-arrow-right"></i></button>',
                dots:false,
                appendArrows: $(this).parent().find('.arrows-only'),
                slidesToShow: 1,
                slidesToScroll: 1,
				centerMode: true,

                responsive: [

                    {
                    breakpoint:767,
                    settings: {
                        slidesToShow: 1,
                        }
                    },

                    {
                    breakpoint:769,
                    settings: {
                        centerPadding: '200px',
                    	},					
                	},
					{
					breakpoint:1000,
					settings: {
						centerPadding: '250px',
						},					
					},
					{
						breakpoint:1150,
						settings: {
							centerPadding: '400px',
							},					
						}
            ],
            });
        })
    }
    galleryCenter = () => {
		var carousel = $('.carousel[data-carousel="gallery-center"]');
        
        carousel.each(function(){
            var arrowsContainer = '<div class="arrows-only"></div>';
            $(this).parent().append(arrowsContainer);
            
            $(this).not('.slick-initialized').slick({
                mobileFirst: true,
                arrows: true,
                prevArrow: '<button type="button" class="btn-prev"><i class="bi bi-arrow-left"></button>',
                nextArrow: '<button type="button" class="btn-next"><i class="bi bi-arrow-right"></button>',
                dots:false,
                appendArrows: $(this).parent().find('.arrows-only'),
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                autoplay: false,
                adaptiveHeight: true,
                
            });
        })
    }
    
    productList = () => {
		var carousel = $('.carousel[data-carousel="product-list"]');
        
        carousel.each(function(){
            var arrowsContainer = '<div class="arrows-only d-flex justify-content-center"></div>';
            $(this).parent().append(arrowsContainer);
            
            $(this).not('.slick-initialized').slick({
                rows:1,
                mobileFirst: true,
                arrows: true,
                prevArrow: '<button type="button" class="btn-prev d-flex align-items-center"><i class="bi bi-arrow-left"></i><span>PRECEDENTE</span></button>',
                nextArrow: '<button type="button" class="btn-next d-flex align-items-center"><span>SUCCESSIVO</span><i class="bi bi-arrow-right"></button>',
                dots:false,
                appendArrows: $(this).parent().find('.arrows-only'),
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                autoplay: false,
                adaptiveHeight: true,
                responsive: [

                    {
                    breakpoint:767,
                    settings: {
                        rows:2,
                        slidesToShow: 2,
                        }
                    },
                    {
                    breakpoint:900,
                    settings: {
                        rows:2,
                        slidesToShow: 3,
                        }
                    }
            ],    
                
            });
        })
    }
    
    megaMenuKinds = () => {
		var carousel = $('.carousel[data-carousel="mega-menu-kinds"]');
        
        carousel.each(function(){
            var arrowsContainer = '<div class="arrows-only d-flex justify-content-center"></div>';
            $(this).parent().append(arrowsContainer);
            
            $(this).not('.slick-initialized').slick({
                mobileFirst: true,
                arrows: true,
                prevArrow: '<button type="button" class="btn-prev d-flex align-items-center"><i class="bi bi-arrow-left"></i><span></span></button>',
                nextArrow: '<button type="button" class="btn-next d-flex align-items-center"><span></span><i class="bi bi-arrow-right"></button>',
                dots:false,
                appendArrows: $(this).parent().find('.arrows-only'),
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                autoplay: false,
                adaptiveHeight: true,

                responsive: [

                    {
                    breakpoint:767,
                    settings: {
                        slidesToShow: 4,
                        }
                    },
					{
					breakpoint:1000,
					settings: {
						slidesToShow: 6,
						},					
					},
					{
						breakpoint:1150,
						settings: {
							slidesToShow: 6,
							},					
						}
            ],        
            });
        })
    }

    megaMenuKindsAlternative = () => {
		var carousel = $('.carousel[data-carousel="mega-menu-kinds-alternative"]');
        
        carousel.each(function(){
            var arrowsContainer = '<div class="arrows-only d-flex justify-content-center"></div>';
            $(this).parent().append(arrowsContainer);
            
            $(this).not('.slick-initialized').slick({
                mobileFirst: true,
                arrows: true,
                prevArrow: '<button type="button" class="btn-prev d-flex align-items-center"><i class="bi bi-arrow-left"></i><span></span></button>',
                nextArrow: '<button type="button" class="btn-next d-flex align-items-center"><span></span><i class="bi bi-arrow-right"></button>',
                dots:false,
                appendArrows: $(this).parent().find('.arrows-only'),
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                autoplay: false,
                adaptiveHeight: true,

                responsive: [

                    {
                    breakpoint:767,
                    settings: {
                        slidesToShow: 3,
                        }
                    },
					{
					breakpoint:1000,
					settings: {
						slidesToShow: 4,
						},					
					},
					{
						breakpoint:1150,
						settings: {
							slidesToShow: 4,
							},					
						}
            ],        
            });
        })
    }
    
    megaMenuKindsNav = () => {
        var carousel = $('.carousel[data-carousel="mega-menu-kinds-nav"]');
        
        carousel.each(function(){
            var arrowsContainer = '<div class="arrows-only d-flex justify-content-center"></div>';
            $(this).parent().append(arrowsContainer);
            
            $(this).not('.slick-initialized').slick({
                mobileFirst: true,
                arrows: true,
                prevArrow: '<button type="button" class="btn-prev d-flex align-items-center"><i class="bi bi-arrow-left"></i><span></span></button>',
                nextArrow: '<button type="button" class="btn-next d-flex align-items-center"><span></span><i class="bi bi-arrow-right"></button>',
                dots:false,
                appendArrows: $(this).parent().find('.arrows-only'),
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
                autoplay: false,
                adaptiveHeight: true,
    
                responsive: [
    
                    {
                    breakpoint:767,
                    settings: {
                        slidesToShow: 4,
                        }
                    },
                    {
                    breakpoint:1000,
                    settings: {
                        slidesToShow: 6,
                        },					
                    },
                    {
                        breakpoint:1150,
                        settings: {
                            slidesToShow: 6,
                            },					
                        }
            ],        
            });
        })
    }

    reference= () => {
		var carousel = $('.carousel[data-carousel="reference"]');
        
        carousel.each(function(){
            var arrowsContainer = '<div class="arrows-only d-flex justify-content-center py-5"></div>';
            $(this).parent().append(arrowsContainer);
            
            $(this).not('.slick-initialized').slick({
                mobileFirst: true,
                arrows: true,
                prevArrow: '<button type="button" class="btn-prev d-flex align-items-center"><i class="bi bi-arrow-left"></i><span></span></button>',
                nextArrow: '<button type="button" class="btn-next d-flex align-items-center"><span></span><i class="bi bi-arrow-right"></button>',
                dots:false,
                appendArrows: $(this).parent().find('.arrows-only'),
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                autoplay: true,
                adaptiveHeight: true,

                responsive: [

                    {
                    breakpoint:767,
                    settings: {
                        slidesToShow: 1,
                        }
                    },
					{
					breakpoint:1000,
					settings: {
						slidesToShow: 1,
						},					
					},
					{
						breakpoint:1150,
						settings: {
							slidesToShow: 1,
							},					
						}
            ],        
            });
        })
    }
}





