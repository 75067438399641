import { app } from '../../utils/store';

export default class Modal {
    constructor() {
        this.menu = {
            toggler: document.getElementById('menu-toggler'),
            main: document.getElementById('main-menu')
        };
        
        this.modal = {
            el: document.querySelectorAll('[data-modal]'),
            toggler: document.querySelectorAll('.btn-modal'),
            video: document.querySelectorAll('.modal-video'),
            close_modal: document.getElementById('close-modal'),
            // popup: document.getElementById('#popup-modal'),
            youtube: document.querySelector('[data-modal][data-youtube]'),
        };

        this.video = {
            toggler: document.querySelectorAll('.btn-video'),
        }

        this.header = document.querySelector('header');
        this.body = document.querySelector('body');
    }

    init() {
        this.toggle();
        // this.play();
        if(this.modal.youtube){ this.YouTube() }
        // if(!Sniff.features.mobile){ this.language(); }
        // if(this.form.newsletter){
        //     this.newsletter();
        // }
    }

    toggle = () => {
        // var myModal = new bootstrap.Modal(document.getElementById("popup-modal"), {});
        // setTimeout(function () {
        //     myModal.show();
        // }, 2000);

        if (this.modal.toggler) {
            this.modal.toggler.forEach((toggler) => {
                toggler.addEventListener('click', () => {
                    var data_modal = toggler.getAttribute('data-target');
                    // console.log(data_modal);
                    var modal = document.querySelector('[id="' + data_modal + '"]');
                    // console.log(modal);
                    
                    modal.classList.add('active');
                    
                    if(modal.classList.contains('modal-video')){
                        this.header.classList.add('modal-video');
                        this.header.classList.add('modal-active');
                        if(!modal.getAttribute('data-youtube')){
                            modal.querySelector('video').play();
                        }
                    } else {
                        this.header.classList.add('modal-active');
                    }

                    // this.menu.toggler.classList.add('active');
                    this.body.classList.add('overflow-hidden');
                });
            });
            
            // this.modal.el.forEach((modal) => {
            //     var close_modal = modal.querySelector('.close-modal')
            //     var main_container_modal = modal.querySelector('.all-modal')
            //     close_modal.addEventListener('click', () => {
            //         modal.classList.remove('active');

            //         // this.menu.toggler.classList.remove('active');
            //         this.body.classList.remove('overflow-hidden');
            //     });
            //     main_container_modal.addEventListener('click', () => {
            //         console.log('click su container principale')
            //     });
            // });

            this.modal.el.forEach((modal) => {
                var close_modal = modal.querySelectorAll('.close-modal');
                close_modal.forEach((toggler) => {
                    toggler.addEventListener('click', () => {
                        modal.classList.remove('active');
    
                        // this.menu.toggler.classList.remove('active');
                        this.body.classList.remove('overflow-hidden');
                    });
                });
            });

            

            // this.menu.toggler.addEventListener('click', () => {
            //     if (this.header.classList.contains('modal-active') && !this.header.classList.contains('modal-video')) {
            //         this.header.classList.remove('modal-active');
            //         this.menu.toggler.classList.remove('active');
            //         this.body.classList.remove('overflow-hidden');
            //         document.querySelector('[data-modal].active').classList.remove('active');
            //     } else if(this.header.classList.contains('modal-video')){
            //         this.header.classList.remove('modal-active');
            //         this.header.classList.remove('modal-video');
            //         this.menu.toggler.classList.remove('active');
            //         this.body.classList.remove('overflow-hidden');
            //         if(!document.querySelector('[data-modal].active').getAttribute('data-youtube')){
            //             document.querySelector('[data-modal].active').querySelector('video').pause();
            //             document.querySelector('[data-modal].active').querySelector('video').currentTime = 0;
            //         }
            //         document.querySelector('[data-modal].active').classList.remove('active');
            //     }   
            // });
        }
    }

    play = () => {
        
    }

    YouTube = () => {
        // this.modal.video.forEach((modal)=>{
        //     // console.log(modal.id);
            
        //     var iframe = modal.querySelector('#player-'+modal.id);
        //     var embed_id = modal.getAttribute('data-youtube');

        //     var src_video = 'https://www.youtube.com/embed/'+embed_id+'?autoplay=1&amp;rel=0&amp;fs=0&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0'
        //     // console.log(iframe.id + ' : ' + embed_id);

        //     iframe.setAttribute('src',src_video);
        // });

        this.video.toggler.forEach((toggler)=>{
            toggler.addEventListener('click',(play)=>{
                var modal = document.getElementById(play.target.getAttribute('data-target'));
                var iframe = modal.querySelector('#player-'+modal.id);
                var embed_id = modal.getAttribute('data-youtube');
                var src_video = 'https://www.youtube-nocookie.com/embed/'+embed_id+'?autoplay=1&amp;rel=1&amp;fs=0&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;controls=1'

                iframe.setAttribute('src',src_video);
            });

            this.menu.toggler.addEventListener('click',()=>{
                var modal = document.querySelectorAll('.modal-video');

                setTimeout(()=>{
                    modal.forEach((modal)=>{
                        var iframe = modal.querySelector('iframe');
                        iframe.removeAttribute('src');
                    })
                },1000);
            });
        });
    }
}