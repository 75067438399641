import $ from 'jquery';
export default class Counters {
    constructor() {
        this.number = {
            counter: document.querySelectorAll('.counters .value-counter'),
        }
    }

    init() {
        this.counter();
        // if(!Sniff.features.mobile){ this.language(); }
    }

    counter = () => {
        $('.counters .value-counter').each(function () {
            var that = $(this);
            var countTo = that.attr('data-value');

            $({ countNum: that.text() }).animate({
                countNum: countTo
            },{
                duration: 5000,
                easing: 'linear',
                step: function () {
                    that.text(commaSeparateNumber(Math.floor(this.countNum)));
                },
                complete: function () {
                    that.text(commaSeparateNumber(this.countNum));
                }
            });
        });

        function commaSeparateNumber(val) {
            while (/(\d+)(\d{3})/.test(val.toString())) {
                val = val.toString().replace(/(\d+)(\d{3})/, '$1' + '.' + '$2');
            }
            return val;
        }
    }
}
