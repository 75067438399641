import { app } from '../../utils/store';
export default class Menu {
    constructor() {
        this.menu = {
            toggler: document.getElementById('menu-toggler'),
            main: document.getElementById('mobile-menu'),
            subMegaMenu: document.getElementById('item-pr'),
            megaMenu: document.getElementById('mega-menu-kinds-nav'),
            productSubMenu: document.querySelector('#mobile-menu #item-pr'),
            allItemMenu: document.querySelectorAll('#mobile-menu li'),
        }
        this.body = document.querySelector('body');
        this.search = {
            toggler: document.getElementById('search-toggler'),
            main: document.getElementById('search'),
        }
        app.overlay = document.querySelector('.app-overlay');
        // this.lang = {
        //     toggler: document.querySelector('#select-language .dropdown-toggler'),
        //     menu: document.querySelector('#select-language .dropdown-menu'),
        // }
    }

    init() {
        this.toggle();
        // if(!Sniff.features.mobile){ this.language(); }
    }

    toggle = () => {
        this.menu.toggler.addEventListener('click', () => {
            this.menu.main.classList.toggle('active');
            this.menu.toggler.classList.toggle('active');
            document.body.classList.toggle('hidden');
            app.overlay.classList.toggle('active');
            // this.lang.menu.classList.remove('show');
        });
        this.menu.subMegaMenu.addEventListener('click', (e) => {
            this.menu.megaMenu.classList.toggle('hidden-submenu');
            this.search.main.classList.add('hidden-search');
            this.search.toggler.classList.toggle('no-events');
            this.body.classList.toggle('overflow-hidden');
            e.preventDefault();
            app.overlay.classList.toggle('active');
        });
        app.overlay.addEventListener('click', (e) => {
            this.menu.megaMenu.classList.add('hidden-submenu');
            e.preventDefault();
            app.overlay.classList.remove('active');
            this.menu.subMegaMenu.classList.remove('no-events');
            this.search.toggler.classList.remove('no-events');
            this.search.main.classList.add('hidden-search');
            this.search.toggler.classList.remove('active-search');
            this.search.toggler.classList.remove('disabled');
            this.body.classList.remove('overflow-hidden');
            this.menu.toggler.classList.remove('no-events');
        });
        this.menu.productSubMenu.addEventListener('click', () => {
            this.menu.productSubMenu.classList.toggle('active');
            
            this.menu.allItemMenu.forEach((allItemMenu)=> {
                    allItemMenu.classList.toggle('deactive');
            });
            // this.lang.menu.classList.remove('show');
        });
    }

    // language = () => {
    //     this.lang.toggler.addEventListener('click', () => {
    //         this.lang.menu.classList.toggle('show');
    //     });
    // }
}
